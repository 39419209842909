<template>
  <div class="mb-3">

    <Header/>

    <main>
      <div class="container-fluid">
        <div class="row mt-1">
          <div class="col-lg-12 offset-lg-0">
            <router-view/>
          </div>
        </div>
      </div>
    </main>

    <Footer/>

  </div>
</template>

<script>
import Header from './components/HeaderComponent.vue';
import Footer from './components/FooterComponent.vue';
const LogHeader =  'App.vue';

export default {
  components: {
    Header,
    Footer,
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    // httpStatus() { return this.$store.state.error.code; },
  },
  methods: {
  },
};
</script>
