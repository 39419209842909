import { HTTP_STATUS } from '../util'

const state = {
  code: null,
  message: null,
  errors: null,
};

const getters = {
  isHttpStatusOK: state => state.code === null || state.code == HTTP_STATUS.OK,
  hasErrors: state => state.errors != null,
};

const mutations = {
  reset(state) {
    state.code = null;
    state.message = null;
    state.errors = null;
  },
  setCode(state, code) {
    state.code = code || null;
  },
  setMessage(state, message) {
    state.message = message || null;
    if (state.message == 'The given data was invalid.') {
      state.message = '入力された内容に誤りがあります。';//LaravelのValidationExceptionの既定文言をサーバー側で簡単に上書きできないのでここで上書き
    }
  },
  setErrors(state, errors) {
    state.errors = errors || null;
  },
  setResult(state, response) {
    state.code = response? response.status: null;
    state.message = response&&response.data? response.data.message: null;
    if (state.message == 'The given data was invalid.') {
      state.message = '入力された内容に誤りがあります。';//setMessage()と冗長なので解消したい
    }
    state.errors = response&&response.data? response.data.errors: null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
