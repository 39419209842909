<template>
  <div>

    <div class="row mt-3">
      <div class="col-12 col-md-auto mx-md-auto col-lg-10 col-xl-7 outer-card">
        <div class="card border-info">

          <div class="card-header text-white bg-nav_header">
            <div class="row">
              <div class="col-12 col-lg-4 _pt-sm-1"><!-- ログアウトボタン等を標準サイズにする場合、この pt-sm-1 は↓のh5を上下中央にする為に必要 -->
                <div class="new_my_h5">WEBテスト 試験種 {{ examination? examination.exam_name: '' }}</div>
              </div>
              <div v-if="student" class="col-12 col-lg-8 mt-2 mt-lg-0 text-right">
                <div class="new_my_h6">{{ student.school.school_name }}&nbsp;{{ student.school_faculty? `${student.school_faculty.faculty_name} `: '' }}{{ student.school_department? `${student.school_department.department_name} `: '' }}</div>
                <div class="new_my_h6">{{ student.student_no? `${student.student_no}　`: '' }}{{ student.full_name? `${student.full_name} さん `: '' }}</div>
              </div>
            </div>
          </div><!-- card-header -->

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-right">
                <router-link v-if="student/*&&!ogExPrtSets['本テスト'].finished_at*/" class="btn btn-sm btn-light_gray mx-1 mb-1 px-3" :to="{ name: 'profile' }">&nbsp;登録情報の確認・訂正&nbsp;</router-link>
                <button class="btn btn-sm btn-light_gray mx-2 mb-1 px-3" href="#" @click.prevent="onClickLogoutButton">ログアウト</button>
              </div>
            </div>

            <template v-if="schoolExamination">

              <div class="row mt-4 px-1 px-md-5">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 card-title bg-light my_h5 font-weight-bold">本テスト</div>
                  </div>
                  <div class="row mt-2 px-1 px-md-5">
                    <ul class="col-12 list-styled">
                      <li>試験開始後は最後まで受験してください。</li>
                      <li>ブラウザの更新ボタン<span class="reload-icon-black"></span>は使わないでください（受験が中断される場合があります）。</li>
                      <li v-if="canUseCalculator" class="text-red">電卓を使⽤できます。また、筆記⽤具と計算⽤紙をご準備ください。</li>
                      <li v-else class="text-red">電卓は使⽤できません。筆記⽤具と計算⽤紙をご準備ください。</li>
                      <li v-if="hasTextInput" class="text-red">特殊文字・環境依存文字は入力しないでください（不正解になります）。</li>
                      <li>受験後に「解説を⾒る」ボタンおよび「試験結果」ボタンが表⽰されますので、復習の際にご活⽤ください。</li>
                    </ul>
                  </div><!-- row -->
                  <div class="row mt-2 px-1 px-md-5">
                    <div class="col-12">
                      <div class="d-md-inline">
                        <router-link v-if="ogExPrtSets['本テスト'].started_at&&!ogExPrtSets['本テスト'].finished_at" tag="button" :to="{ name: 'exam', params: { examPartSetId: '本テスト' } }" class="btn btn-lg btn-red mx-3 px-4 py-1 my_h5" :disabled="schoolExamination.is_disabled">本テストを再開する</router-link>
                        <button v-else class="btn btn-lg btn-red mx-3 px-4 py-1 my_h5" data-toggle="modal" data-target="#modalStart" :disabled="schoolExamination.is_disabled||!!ogExPrtSets['本テスト'].finished_at">本テストを受験する</button>
                      </div>
                      <div class="d-md-inline">
                        <router-link tag="button" :to="{ name: 'result', params: { examPartSetId: '本テスト', showDescAtFirst: true } }" class="btn btn-sm btn-light_blue1 mx-3 my-1 px-4 py-1 my_h6" :disabled="!ogExPrtSets['本テスト'].has_personal_report_set">解説を見る</router-link>
                        <router-link tag="button" :to="{ name: 'result', params: { examPartSetId: '本テスト', showDescAtFirst: false } }" class="btn btn-sm btn-green mx-3 my-1 px-4 py-1 my_h6" :disabled="!ogExPrtSets['本テスト'].has_personal_report_set">試験結果</router-link>
                      </div>
                    </div>
                  </div><!-- row -->
                </div><!-- col -->
              </div><!-- row -->

              <div class="row mt-5 px-1 px-md-5">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 card-title bg-light my_h5 font-weight-bold">本テストの復習利用</div>
                  </div>
                  <div class="row mt-2 px-1 px-md-5">
                    <ul class="col-12 list-styled">
                      <li>初回受験の後、復習利⽤として4回受験できます。復習利⽤の受験ボタンは初回受験後に表⽰されます。</li>
                    </ul>
                  </div><!-- row -->
                  <div class="row mt-2 px-1 px-md-5">
                    <div class="col-12 my-1" v-for="examPartSetId in [ '復習１', '復習２', '復習３', '復習４' ]" :key="`review_${examPartSetId}`">
                      <div class="d-md-inline">
                        <span class="mr-5">{{ testCaptions[examPartSetId] }}</span>
                      </div>
                      <div class="d-md-inline">
                        <!-- （試験が無効化されているかどうかに関係なく）本テストが完了していれば復習テストは利用可とする -->
                        <router-link v-if="ogExPrtSets[examPartSetId].started_at&&!ogExPrtSets[examPartSetId].finished_at" tag="button" :to="{ name: 'exam', params: { examPartSetId: examPartSetId } }" class="btn btn-sm btn-orange1 mx-3 px-4 py-1 my_h6">再開する</router-link>
                        <button v-else class="btn btn-sm btn-orange1 mx-3 px-4 py-1 my_h6" data-toggle="modal" :data-target="`#modalStart_${examPartSetId}`" :disabled="!prevTestIsFinished(examPartSetId)||!!ogExPrtSets[examPartSetId].finished_at">受験する</button>
                        <router-link tag="button" :to="{ name: 'result', params: { examPartSetId: examPartSetId, showDescAtFirst: false } }" class="btn btn-sm btn-green mx-3 my-1 px-4 py-1 my_h6" :disabled="!ogExPrtSets[examPartSetId].has_personal_report_set">{{ testCaptions[examPartSetId] }} 結果</router-link>
                      </div>
                    </div>
                  </div><!-- row -->
                </div><!-- col -->
              </div><!-- row -->

              <div class="row mt-5 px-1 px-md-5">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 card-title bg-light my_h5 font-weight-bold">練習版テスト</div>
                  </div>
                  <div class="row mt-2 px-1 px-md-5">
                    <ul class="col-12 list-styled">
                      <li>本テストの受験前・受験後のいずれも練習版テストを受験できます。</li>
                      <li>練習版テストは練習回数に制限なく、何度でも受験できます。<span class="text-red">{{ canUseCalculator? '電卓を使⽤できます。': '電卓は使⽤できません。' }}</span></li>
                      <li>練習版テストは２種類あります。</li>
                    </ul>
                  </div><!-- row -->
                  <div class="row mt-2 px-1 px-md-5">
                    <div class="col-12 my-1" v-for="examPartSetId in [ '練習１', '練習２' ]" :key="`practice_${examPartSetId}`">
                      <div class="d-md-inline">
                        <span class="mr-5">{{ testCaptions[examPartSetId] }}</span>
                      </div>
                      <div class="d-md-inline">
                        <!--
                          試験無効化済み かつ 本テスト未完了 の場合は練習版はdisabledとする
                          ＝無効化されていても本テスト受験済みなら利用できる
                          ＝本テスト未完了でも無効化されていなければ利用できる
                        -->
                        <router-link v-if="ogExPrtSets[examPartSetId].started_at&&!ogExPrtSets[examPartSetId].finished_at" tag="button" :to="{ name: 'exam', params: { examPartSetId: examPartSetId } }" class="btn btn-sm btn-orange1 mx-3 px-4 py-1 my_h6" :disabled="schoolExamination.is_disabled&&!ogExPrtSets['本テスト'].finished_at">再開する</router-link>
                        <button v-else class="btn btn-sm btn-orange1 mx-3 px-4 py-1 my_h6" data-toggle="modal" :data-target="`#modalStart_${examPartSetId}`" :disabled="schoolExamination.is_disabled&&!ogExPrtSets['本テスト'].finished_at">受験する</button>
                        <router-link tag="button" :to="{ name: 'result', params: { examPartSetId: examPartSetId, showDescAtFirst: true } }" class="btn btn-sm btn-light_blue1 mx-3 my-1 px-4 py-1 my_h6" :disabled="!ogExPrtSets[examPartSetId].has_personal_report_set">解説を見る</router-link>
                        <router-link tag="button" :to="{ name: 'result', params: { examPartSetId: examPartSetId, showDescAtFirst: false } }" class="btn btn-sm btn-green mx-3 my-1 px-4 py-1 my_h6" :disabled="!ogExPrtSets[examPartSetId].has_personal_report_set">最新の試験結果</router-link>
                      </div>
                    </div>
                  </div><!-- row -->
                </div><!-- col -->
              </div><!-- row -->

            </template><!-- v-if="schoolExamination" -->

            <div class="row mt-3">
              <div class="col-12 text-right">
                <img alt="logo" src="../assets/logo.png">
                <span class="mx-1">株式会社サポートシステム</span>
              </div>
            </div>

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

    <div class="modal fade" id="modalStart" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content bg-light">
          <div class="modal-body">
            <ul class="list-unstyled px-3">
              <li class="my-3">試験を開始する場合は、「受験する」ボタンをクリックしてください。</li>
              <li class="my-3">試験開始後は最後まで受験をしてください。</li>
              <li class="my-3">トップページに戻る場合は、「トップページに戻る」ボタンをクリックしてください。</li>
            </ul>
            <div class="mt-2 text-right">
              <button class="btn btn-header_color mx-2" data-dismiss="modal">トップページに戻る</button>
              <router-link tag="button" :to="{ name: 'exam', params: { examPartSetId: '本テスト' } }" class="btn btn-red mx-2" data-dismiss="modal">受験する</router-link>
            </div>
          </div>
        </div>
      </div>
    </div><!-- modal -->

    <div v-for="examPartSetId in [ '復習１', '復習２', '復習３', '復習４', '練習１', '練習２' ]" :key="`modalStart_${examPartSetId}`" class="modal fade" :id="`modalStart_${examPartSetId}`" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content bg-light">
          <div class="modal-body">
            <ul class="list-unstyled px-3">
              <li class="my-3">試験を開始する場合は、「受験する」ボタンをクリックしてください。</li>
              <li class="my-3">試験開始後は最後まで受験をしてください。</li>
              <li class="my-3">トップページに戻る場合は、「トップページに戻る」ボタンをクリックしてください。</li>
            </ul>
            <div class="mt-2 text-right">
              <button class="btn btn-header_color mx-2" data-dismiss="modal">トップページに戻る</button>
              <router-link tag="button" :to="{ name: 'exam', params: { examPartSetId: examPartSetId } }" class="btn btn-orange1 mx-2" data-dismiss="modal">受験する</router-link>
            </div>
          </div>
        </div>
      </div>
    </div><!-- modal -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
const LogHeader = 'Home.vue';

export default {
  //PageTitle: 'Webテスト',
  mixins: [commonMixin],
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      resourceApi,
      schoolExamination: null,
      examination: null,
      examPartSets: null,
      student: null,
      testCaptions: {//Home,Exam,Resultで同一の定義
        本テスト: '',
        復習１: '復習利用①',
        復習２: '復習利用②',
        復習３: '復習利用③',
        復習４: '復習利用④',
        練習１: '練習版①',
        練習２: '練習版②',
      },
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true, 'データを取得しています...');
    const url = `school_examination`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.schoolExamination = response.data.school_examination;
        this.examination = response.data.examination;
        this.examPartSets = response.data.exam_part_sets;
        this.student = response.data.student;
        this.setLoadingStatus(false);

        if (!this.student.registered_at) {//プロフィール未登録ならプロフィール画面へリダイレクト
          console.log('redirecting home to profile');
          this.$router.push({ name: 'profile'});
        }
        else if (!this.student.checked_precautions_at) {//注意事項未確認なら注意事項画面へリダイレクト
          console.log('redirecting home to precautions');
          this.$router.push({ name: 'precautions'});
        }
        // else if (this.schoolExamination.has_questionnaire &&//アンケートあり？
        //             !this.student.questionnaire_answer_set.answered_at) {//アンケート未回答ならアンケート画面へリダイレクト
        //   console.log('redirecting home to questionnaire');
        //   this.$router.push({ name: 'questionnaire'});
        // }

        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      });
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    ogExPrtSets() { return this.student.organized_exam_part_sets; },
    canUseCalculator() { return [ 'WT' ].includes(this.examination.exam_name.substr(0,2).toUpperCase()); },//計算機使用可？
    hasTextInput() { return [ 'WT' ].includes(this.examination.exam_name.substr(0,2).toUpperCase()); },//テキスト入力の問題あり？
  },
  methods: {
    onPopState() {
      console.log(`[${LogHeader}] onPopState() CALLED`);
      window.history.pushState(null, null, null);
      alert('ブラウザの「戻る」は使用できません。');
    },
    prevTestIsFinished($examPartSetId) {
      switch ($examPartSetId) {
      case '復習１':
        return !!this.ogExPrtSets['本テスト'].finished_at;
      case '復習２':
        return !!this.ogExPrtSets['復習１'].finished_at;
      case '復習３':
        return !!this.ogExPrtSets['復習２'].finished_at;
      case '復習４':
        return !!this.ogExPrtSets['復習３'].finished_at;
      }
      return false;
    },
  },
}
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"
.outer-card
  width: 80rem
ul
  list-style-type: none
  padding-left: 0
  li
    $wh: 0.2em
    $mr: 0.5em
    padding-left: $wh + $mr
    text-indent: -($wh + $mr)
    &:before
      content: ''
      display: inline-block
      width: $wh
      height: $wh
      background: #000000
      border-radius: 50%
      vertical-align: middle
      margin-bottom: 0.2em //vertical-align:middleでも微妙にずれるので補正
      margin-right: $mr
</style>
