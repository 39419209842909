import { HTTP_STATUS } from '../util';

const BaseUrl = '/api/student/auth';

const state = {
  user: null,
};

const getters = {
  isLoggedIn: state => !!state.user,
  userId: state => state.user? state.user.id: null,
  userName: state => state.user? state.user.name: '',
  user: state => state.user? state.user: null,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    if (user == null) {
      window.token = sessionStorage.token = '';
    }
  },
};

const actions = {
  async login(context, data) {
    console.log('[store/auth] login() CALLED', data);
    const response = await axios.post(`${BaseUrl}/login`, data)
                                 .catch(err => err.response || err);
    console.log(`[store/auth] login() POST ${BaseUrl}/login response=↓`);
    console.log(response);
    context.commit('error/setResult', response, { root: true });
    if (response.status === HTTP_STATUS.OK) {
      context.commit('setUser', response.data.user);
      window.token = sessionStorage.token = response.data.access_token;//他に画面単位でセットする機会があるので留意
    }
  },
  async logout(context) {
    console.log('[store/auth] logout() CALLED');
    const response = await axios.post(`${BaseUrl}/logout`)
                                .catch(err => err.response || err);
    console.log(`[store/auth] logout() POST ${BaseUrl}/logout response=↓`);
    console.log(response);
    context.commit('error/setResult', response, { root: true });
    if (response.status === HTTP_STATUS.OK) {
      context.commit('setUser', null);
    }
  },
  async authedUser(context) {
    console.log('[store/auth] authedUser() CALLED');
    const response = await axios.get(`${BaseUrl}/authed_user`)
                                .catch(err => err.response || err);
    console.log(`[store/auth] authedUser() GET ${BaseUrl}/authed_user response=↓`);
    console.log(response);
    context.commit('error/setResult', response, { root: true });
    if (response.status === HTTP_STATUS.OK) {
      context.commit('setUser', response.data.user);
    }
  },
  async mail(context, data) {
    console.log('[store/auth] mail() CALLED', data);
    const response = await axios.post(`${BaseUrl}/mail`, data)
                                 .catch(err => err.response || err);
    console.log(`[store/auth] mail() POST ${BaseUrl}/mail response=↓`);
    console.log(response);
    context.commit('error/setResult', response, { root: true });
    if (response.status === HTTP_STATUS.OK) {
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
