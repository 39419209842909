const BaseUrl = '/api/student';
export default {
  data() {
    // console.log('[resource_api] data() START/END');
    return {
      defaultResource: null,
    };
  },

  async getAll(params) {
    console.log(`[resource_api](${this.defaultResource}) getAll(params)`, params);
    return await axios.get(`${BaseUrl}/${this.defaultResource}`, { params });
  },
  async create(data) {
    console.log(`[resource_api](${this.defaultResource}) create(data)`, data);
    return await axios.post(`${BaseUrl}/${this.defaultResource}`, data);
  },
  async getOne(id) {
    console.log(`[resource_api](${this.defaultResource}) getOne(${id})`);
    return await axios.get(`${BaseUrl}/${this.defaultResource}/${id}`);
  },
  async update(id, data) {
    console.log(`[resource_api](${this.defaultResource}) update(${id}, data)`, data);
    return await axios.put(`${BaseUrl}/${this.defaultResource}/${id}`, data);
  },
  async delete(id) {
    console.log(`[resource_api](${this.defaultResource}) delete(${id})`);
    return await axios.delete(`${BaseUrl}/${this.defaultResource}/${id}`);
  },

  async getSpecial(url, config) {
    console.log(`[resource_api] getSpecial(${url})`);
    if (config) {
      console.log(config);
      return await axios.get(`${BaseUrl}/${url}`, config);
    }
    return await axios.get(`${BaseUrl}/${url}`);
  },
  async postSpecial(url, data, config) {
    console.log(`[resource_api] postSpecial(${url}, data)`, data);
    if (config) {
      console.log(config);
      return await axios.post(`${BaseUrl}/${url}`, data, config);
    }
    return await axios.post(`${BaseUrl}/${url}`, data);
  },
  async putSpecial(url, data, config) {
    console.log(`[resource_api] putSpecial(${url}, data)`, data);
    if (config) {
      console.log(config);
      return await axios.put(`${BaseUrl}/${url}`, data, config);
    }
    return await axios.put(`${BaseUrl}/${url}`, data);
  },
  async deleteSpecial(url, config) {
    console.log(`[resource_api] deleteSpecial(${url})`);
    if (config) {
      console.log(config);
      return await axios.delete(`${BaseUrl}/${url}`, config);
    }
    return await axios.delete(`${BaseUrl}/${url}`);
  },
}
