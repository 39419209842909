<template>
  <div>

    <div v-if="isLoading||isSaving" class="modal show text-center" style="display:block;">
      <div class="spinner-border text-light" role="status">
        <span class="sr-only">Wait...</span>
      </div>
    </div>
    <div v-if="isLoading||isSaving" class="modal-backdrop show"></div>

    <div v-if="statusMessage" class="alert alert-success" role="alert">{{ statusMessage }}</div>

    <div v-if="errorMessage||errors" class="alert alert-danger" role="alert">
      <p v-if="errorMessage" class="mb-0">{{ errorMessage }}</p>
      <ul class="mt-2 mb-1" v-for="(errMsgs, errHeader) in errors" :key="`errHeader_${errHeader}`" :data-error="errHeader">
        <li v-for="errMsg in errMsgs" :key="`errMsg_${errMsg}`">{{ errMsg }}</li>
      </ul>
    </div>

  </div>
</template>

<script>
const LogHeader =  'StatusMessageComponent';

export default {
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    errorMessage() { return this.$store.state.error.message; },
    errors() { return this.$store.state.error.errors; },
    isLoading() { return this.$store.state.status.isLoading; },
    isSaving() { return this.$store.state.status.isSaving; },
    statusMessage() { return this.$store.state.status.message; },
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
$diameter: 20vh //単位はvhのみ
.spinner-border
  width: $diameter
  height: $diameter
  margin-top: (100vh - $diameter) / 2
</style>
