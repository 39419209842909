
export const HTTP_STATUS = {
  OK: 200,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOKEN_MISMATCH: 419,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};

export function getCookieValue(searchKey) {
  if (typeof searchKey !== 'undefined') {
    document.cookie.split(';').forEach(cookie => {
      const [key, value] = cookie.split('=');
      if (key === searchKey) {
        return value;
      }
    });
  }
  return '';
};
