import { getCookieValue } from './util';

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

//XXX JWTトークンをメモリのみに保持する場合は↓のifを常に偽とすればよい
//但し、ブラウザのリロードでも再ログインが必要になる
if (sessionStorage.token) {
    window.token = sessionStorage.token;
} else {
    window.token = '';
}

// window.axios.defaults.withCredentials = true;
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.request.use(config => {
    console.log(`[axios.interceptors.request] config=↓`);
    console.log(config);
    // config.headers['X-XSRF-TOKEN'] = getCookieValue('XSRF-TOKEN');
    if (window.token) {//window.tokenはauth/login成功時にセットされる（そしてauth/setUser(null)時にクリアされる）
        config.headers['Authorization'] = `Bearer ${window.token}`;
    }
    return config;
});
