require('./bootstrap');

import Vue from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../common/sass/common.sass';

Vue.config.productionTip = false;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
//
// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

const createApp = async () => {

  await store.dispatch('auth/authedUser');//ユーザー認証状態取得

  const app = new Vue({
      router,
      store,
      render: h => h(App),
  });

  router.beforeEach((to, from, next) => {
    console.log(`app.js beforeEach() CALLED`);
    app.$store.commit('error/reset');
    app.$store.commit('status/reset');
    console.log('from ↓', from);
    console.log('to ↓', to);
    let isLoggedIn = store.getters['auth/isLoggedIn'];
    console.log(`isLoggedIn=${isLoggedIn}`);
    if (to.meta.requiresLogin && !isLoggedIn) {
      console.log(`NOT LOGGED IN !!`);
      console.log(`redirecting to login`);
      next({//未ログインならログイン画面へ
        name: 'login',
      });
    } else {
      next();
    }
  });

  app.$mount('#app');

};
createApp();
