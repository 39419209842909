
const state = {
  isLoading: false,
  isSaving: false,
  message: null,
};

const getters = {
  isAvailable: state => !state.isLoading && !state.isSaving,
};

const mutations = {
  reset(state) {
    state.isLoading = false;
    state.isSaving = false;
    state.message = null;
  },
  setLoading(state, payload) {
    state.isLoading = payload.value;
    state.message = payload.message || null;
  },
  setSaving(state, payload) {
    state.isSaving = payload.value;
    state.message = payload.message || null;
  },
  setMessage(state, message) {
    state.message = message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
